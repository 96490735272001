import styled from 'styled-components';

import { colors } from 'utils/styleConstants';
import Button from 'components/button/Button';
import { Header3 } from 'utils/fontStyles';
import { useTranslation } from 'react-i18next';
import Section from 'components/section';

import { ReactComponent as SingleTailNote } from 'assets/SingleTailNote.svg';
import { ReactComponent as EighthNotes } from 'assets/EighthNotes.svg';
import { ReactComponent as Semiquaver } from 'assets/Semiquaver.svg';

const PanelContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;
`;

const AboutBox = styled.div`
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 30rem;
  height: 32rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  box-shadow: 0 1.2rem 1rem 0.1rem rgba(0, 0, 0, 0.2);

  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

const Content = styled(Header3)`
  color: ${colors.background};
  margin-bottom: 3rem;
`;

const Panel = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <PanelContainer>
        <AboutBox color={colors.panelBlue}>
          <SingleTailNote />
          <Content>{t('AboutUs')}</Content>
          <Button btnType='secondary'>{t('clickHere')}</Button>
        </AboutBox>
        <AboutBox color={colors.panelPurple}>
          <EighthNotes />
          <Content>{t('Enrol')}</Content>
          <Button btnType='secondary'>{t('clickHere')}</Button>
        </AboutBox>
        <AboutBox color={colors.panelOrange}>
          <Semiquaver />
          <Content>{t('OurPrograms')}</Content>
          <Button btnType='secondary'>{t('clickHere')}</Button>
        </AboutBox>
      </PanelContainer>
    </Section>
  );
};

export default Panel;
