import styled from 'styled-components';

import { ModalType } from 'components/types';
import { zIndex } from 'utils/styleConstants';

const ModalContainer = styled.div`
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${zIndex.modal};
  }

  .modal-content {
    background-color: white;
  }
`;

const Modal = ({ handleClose, isShow, children }: ModalType) => {
  return isShow ? (
    <ModalContainer>
      <div
        className='modal-backdrop'
        onClick={() => {
          // close modal when outside of modal is clicked
          handleClose();
        }}
      >
        <div
          className='modal-content'
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </ModalContainer>
  ) : null;
};

export default Modal;
