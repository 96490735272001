import styled from 'styled-components';

import Navigation from 'components/header/Navigation';

const Header = () => {
  return (
    <PageHeaderContainer>
      <Navigation />
    </PageHeaderContainer>
  );
};

const PageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default Header;
