const fontSizes = {
  xs: '1.1rem',
  sm: '1.4rem',
  md: '1.6rem',
  lg: '2rem',
  xl: '2.2rem',
  xxl: '2.9rem',
  xxxl: '3.6rem',
  title_xs: '2.6rem',
  title_sm: '3.6rem',
  title_md: '4.6rem',
  title_lg: '5.6rem',
  title_xl: '6.6rem',
  title_xxl: '7.6rem',
  title_xxxl: '8.6rem',
};

/**
 * Naming Convention.
 * @link https://codepen.io/nicetransition/pen/QjwbRg
 * @description The link contains the naming conventions of colors used
 */
const colors = {
  // Background
  background: '#FFF',

  // Primary Colors - main color
  primary: '#F3D250',
  primaryLight: '',
  primaryDark: '#54595F',

  // Secondary Colors - complements the main color
  secondary: '#5DA2D5',
  secondaryLight: '',
  secondaryDark: '',

  // Dark Colors
  dark: '#54595F',
  darkDark: '#111111',
  darkLight: '',

  // Light Colors
  light: '#AAAAAA',
  lightDark: '#A4A4A4',
  lightLight: '#FFF',

  // Accent Colors - to highlight/emphasise important content
  accent: '',
  accentDark: '',
  accentLight: '',

  // Panel
  panelBlue: '#009EC6',
  panelPurple: '#801787',
  panelOrange: '#FF7F00',
};

const fontFamily = {
  heading: 'Amatic SC, sans-serif',
  default: 'Montserrat, sans-serif',
  alternate: 'Montserrat Alternates, sans-serif',
};

const zIndex = {
  background: -1,
  foreground: 1,
  navigation: 10,
  modal: 1000,
};

/**
 * Media Query Convention.
 * @link https://jsramblings.com/how-to-use-media-queries-with-styled-components/
 * @description The url contains the media query standards for device sizes
 */
const deviceSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const device = {
  mobileS: `(min-width: ${deviceSize.mobileS})`,
  mobileM: `(min-width: ${deviceSize.mobileM})`,
  mobileL: `(min-width: ${deviceSize.mobileL})`,
  tablet: `(min-width: ${deviceSize.tablet})`,
  laptop: `(min-width: ${deviceSize.laptop})`,
  laptopL: `(min-width: ${deviceSize.laptopL})`,
  desktop: `(min-width: ${deviceSize.desktop})`,
  desktopL: `(min-width: ${deviceSize.desktop})`,
};

export { fontSizes, colors, fontFamily, device, zIndex };
