import styled from 'styled-components';

const DashLine = styled.hr`
  border: none;
  border-top: 0.1rem dashed rgba(0, 0, 0, 0.15);
  height: 0.1rem;
  width: 50%;
  margin-top: 4rem;
  margin-bottom: 3rem;
`;
export default DashLine;
