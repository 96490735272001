import styled from 'styled-components';
import { googleMapUrl } from 'assets/url';

const MapContainer = styled.div`
  margin-top: 2rem;
`;

const MapIFrame = styled.iframe`
  border: 0;
  width: 25vh;
`;

const Map = () => {
  return (
    <MapContainer>
      <MapIFrame title='googleMap' src={googleMapUrl} />
    </MapContainer>
  );
};

export default Map;
