import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors, fontSizes, device, zIndex } from 'utils/styleConstants';
import { Title, Paragraph } from 'utils/fontStyles';
import Button from 'components/button/Button';
import Section, { WAVE_TYPE } from 'components/section';

import { ReactComponent as IconSingleTailNoteSvg } from 'assets/IconSingleTailNote.svg';

interface FeatureImageContainerType {
  headingText: string;
  headingDescriptionText?: string;
  hasEnrolButton?: boolean;
  hasCallButton?: boolean;
}

const FeatureTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85vh;
  min-width: 30vw;
  margin: 0 ${fontSizes.xxxl};
  z-index: ${zIndex.foreground};

  @media ${device.tablet} {
    margin-left: 15vw;
  }
`;

const FeatureTextButtonContainer = styled.div`
  padding: ${fontSizes.md} 0px;
`;

// ----- Feature Image -----
const FeatureImage = (props: FeatureImageContainerType) => {
  const { t } = useTranslation();

  return (
    <Section
      waveTopType={WAVE_TYPE.MULTI}
      waveBottomType={WAVE_TYPE.SINGLE}
      imageSource='https://images.unsplash.com/photo-1571974599782-87624638275e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80'
    >
      <FeatureTextContainer>
        <Title color={colors.lightLight}>{props.headingText}</Title>
        <Paragraph color={colors.lightLight}>
          {props.headingDescriptionText}
        </Paragraph>

        {props.hasEnrolButton ? (
          <FeatureTextButtonContainer>
            <Button>
              {t('buttonEnrolNow')}
              <IconSingleTailNoteSvg className='btn-svg-icon' />
            </Button>
          </FeatureTextButtonContainer>
        ) : (
          ''
        )}

        {props.hasCallButton ? (
          <FeatureTextButtonContainer>
            <Button>
              {t('buttonCall')}
              <IconSingleTailNoteSvg className='btn-svg-icon' />
            </Button>
          </FeatureTextButtonContainer>
        ) : (
          ''
        )}
      </FeatureTextContainer>
    </Section>
  );
};
export default FeatureImage;
