import styled from 'styled-components';
import { ReactComponent as SingleWaveSvg } from 'assets/SingleWave.svg';
import { ReactComponent as MultiWaveSvg } from 'assets/MultiWave.svg';
import { device } from 'utils/styleConstants';

interface WaveProps {
  faceup?: boolean;
}

const WAVE_HEIGHT_DESKTOP = '75px';
const WAVE_HEIGHT_TABLET = '40px';
const WAVE_WIDTH = '100%';

const SingleWaveStyled = styled(SingleWaveSvg)<{ $rotateImage?: boolean }>`
  width: ${WAVE_WIDTH};
  height: ${WAVE_HEIGHT_TABLET};
  /* the translate is to fix the anti-aliasing issue, which causes a 1px gap for the svg wave depending on user zoom */
  transform: ${(props) =>
    props.$rotateImage ? 'rotate(180deg) translateY(1px)' : 'translateY(1px)'};
  @media ${device.tablet} {
    height: ${WAVE_HEIGHT_DESKTOP};
  }
`;

const MultiWaveStyled = styled(MultiWaveSvg)<{ $rotateImage?: boolean }>`
  width: ${WAVE_WIDTH};
  height: ${WAVE_HEIGHT_TABLET};
  /* the translate is to fix the anti-aliasing issue, which causes a 1px gap for the svg wave depending on user zoom */
  transform: ${(props) =>
    props.$rotateImage
      ? 'rotate(180deg) translateY(-1px)'
      : 'translateY(-1px)'};
  @media ${device.tablet} {
    height: ${WAVE_HEIGHT_DESKTOP};
  }
`;

const WaveSingle = (props: WaveProps) => {
  const rotateWaveSingle = !props.faceup;

  return <SingleWaveStyled $rotateImage={rotateWaveSingle} />;
};

const WaveMulti = (props: WaveProps) => {
  const rotateWaveMulti = props.faceup;

  return <MultiWaveStyled $rotateImage={rotateWaveMulti} />;
};

export { WaveSingle, WaveMulti };
