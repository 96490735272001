import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Instagram } from 'assets/Instagram.svg';
import { ReactComponent as Wechat } from 'assets/WeChat.svg';

import Modal from 'components/Modal';

const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 3rem;
`;

const IconContainer = styled.div`
  margin: 0.5rem;
  cursor: pointer;
`;

const QrCodeContainer = () => {
  return (
    <img src={process.env.PUBLIC_URL + 'assets/wechat-qr.jpeg'} alt='QrCode' />
  );
};

const Media = () => {
  const [isShow, setIsClose] = React.useState(false);

  return (
    <MediaContainer>
      <IconContainer>
        <Wechat onClick={() => setIsClose(true)} />
        <Modal
          handleClose={() => setIsClose(!isShow)}
          isShow={isShow}
          children={<QrCodeContainer />}
        />
      </IconContainer>
      <IconContainer>
        <Instagram />
      </IconContainer>
    </MediaContainer>
  );
};

export default Media;
