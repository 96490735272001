import i18n from 'i18next';
import styled from 'styled-components';
import { colors, fontFamily, fontSizes } from 'utils/styleConstants';

const lngs = {
  en: { nativeName: 'English', shortName: 'EN' },
  cn: { nativeName: '中文', shortName: '中文' },
} as { [key: string]: { [key: string]: string } };

const NavigationLanguages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigationLanguage = styled.a`
  font-family: ${fontFamily.alternate};
  color: ${colors.lightDark};
  font-size: ${fontSizes.sm};

  &:not(:last-child):after {
    content: '|';
  }

  &:hover {
    cursor: pointer;
    opacity: 60%;
    transition: opacity 0.5s;
  }
`;

// Navigation: Language Switch
const Language = () => {
  return (
    <NavigationLanguages>
      {Object.keys(lngs).map((lng) => (
        <NavigationLanguage key={lng} onClick={() => i18n.changeLanguage(lng)}>
          {lngs[lng].shortName}
        </NavigationLanguage>
      ))}
    </NavigationLanguages>
  );
};

export default Language;
