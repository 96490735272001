import styled, { keyframes } from 'styled-components';

import {
  pianoBaseData,
  pianoWhiteKeysList,
  pianoBlackKeysList,
  TitleData,
  TitleUnderline,
} from 'assets/LogoDataSource';
import { colors } from 'utils/styleConstants';

// Create the keyframes
const shake = keyframes`
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
`;

// Piano Logo styling
const PianoWhiteKeys = styled.path`
  fill: white;
  animation: ${shake} 0.82s 0.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transition: fill 1.5s;

  &:hover {
    transition: fill 0.1s;
    fill: ${({ color }) => color};
  }
`;

const PianoBlackKeys = styled.path`
  animation: ${shake} 0.82s 0.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  fill: black;
`;

const PianoBase = styled.path`
  animation: ${shake} 0.82s 0.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  fill: black;
`;

const LogoContainer = styled.div`
  display: flex;
`;

const Logo = () => {
  return (
    <LogoContainer>
      <svg width='75' height='75' viewBox='0 0 100 109'>
        <rect
          x='2.5'
          y='11.5'
          width='95'
          height='95'
          fill='white'
          fillOpacity='0.9'
          stroke='black'
          strokeWidth='5'
        />
        {/* Title */}
        <path d={TitleData} fill={colors.darkDark} />
        {/* Title Underline */}
        <path d={TitleUnderline} fill={colors.darkDark} />
        {/* <!-- piano base --> */}
        <PianoBase d={pianoBaseData} fill={colors.darkDark} />
        {/* <!-- white keys --> */}
        {pianoWhiteKeysList.map(({ key, d, color }) => (
          <PianoWhiteKeys key={key} color={color} d={d} />
        ))}
        {/* <!-- black keys --> */}
        {pianoBlackKeysList.map(({ key, d }) => (
          <PianoBlackKeys key={key} d={d} />
        ))}
      </svg>
    </LogoContainer>
  );
};

export default Logo;
