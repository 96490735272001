export const pianoWhiteKeysList = [
  {
    key: 'pianoWhiteKey1',
    d:
      'M15 55V43.1481H53.0488C54.1264 43.1481 55 43.7377 55 44.465V53.6831C55 54.4104 54.1264 55 53.0488 55H34.0244H15Z',
  },
  {
    key: 'pianoWhiteKey2',
    d:
      'M15 40.9259V29.0741H53.0488C54.1264 29.0741 55 29.6637 55 30.3909V39.609C55 40.3363 54.1264 40.9259 53.0488 40.9259H34.0244H15Z',
  },
  {
    key: 'pianoWhiteKey3',
    d:
      'M15 26.8519V15L53.0488 15C54.1264 15 55 15.5896 55 16.3169V25.535C55 26.2623 54.1264 26.8519 53.0488 26.8519L34.0244 26.8519H15Z',
  },
];

export const pianoBlackKeysList = [
  {
    key: 'pianoBlackKey1',
    d:
      'M15 46.8519V37.2222H35.0408C36.1454 37.2222 37.0408 38.1177 37.0408 39.2222V44.8519C37.0408 45.9564 36.1454 46.8519 35.0408 46.8519H15Z',
  },
  {
    key: 'pianoBlackKey2',

    d:
      'M15 32.037V22.4074H35.0408C36.1454 22.4074 37.0408 23.3028 37.0408 24.4074V30.037C37.0408 31.1416 36.1454 32.037 35.0408 32.037H15Z',
  },
];
