import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import About from 'components/About';
import Placeholder from 'components/Placeholder';
import Contact from 'components/contact';
import FeatureImage from 'components/FeatureImage';
import Lessons from 'components/Lessons';
import Panel from 'components/panel';
import Section, { WAVE_TYPE } from 'components/section';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainScreen = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Container>
        <FeatureImage
          headingText={t('headingText')}
          headingDescriptionText={t('headingDescriptionText')}
          hasEnrolButton
          hasCallButton
        />
        <About />
        <Section
          waveTopType={WAVE_TYPE.SINGLE}
          waveBottomType={WAVE_TYPE.MULTI}
          //TODO: imageSource should be imported locally
          imageSource='https://images.unsplash.com/photo-1561014816-50ce5acb5e73?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
          isDarkenImage
        >
          <Placeholder />
        </Section>
        <Lessons />
        <Panel />
        <Section
          waveTopType={WAVE_TYPE.MULTI}
          waveBottomType={WAVE_TYPE.MULTI}
          //TODO: imageSource should be imported locally
          imageSource='https://images.unsplash.com/photo-1475275166152-f1e8005f9854?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
          isDarkenImage
        >
          <Placeholder />
        </Section>
        <Contact />
      </Container>
    </div>
  );
};

export default MainScreen;
