import styled from 'styled-components';
import {
  pianoWhiteKeysList,
  pianoBlackKeysList,
} from 'assets/PianoHamburgerDataSource';

const PianoHamburger = () => {
  return (
    <PianoHamburgerContainer>
      <PianoHamburgerSvg preserveAspectRatio='none' viewBox='0 0 70 70'>
        {/* <!-- Piano Keys --> */}
        {pianoWhiteKeysList.map(({ key, d }) => (
          <PianoHamburgerWhiteKey key={key} d={d} />
        ))}
        {pianoBlackKeysList.map(({ key, d }) => (
          <PianoHamburgerBlackKey key={key} d={d} />
        ))}
      </PianoHamburgerSvg>
    </PianoHamburgerContainer>
  );
};

// Piano Hamburger styling

const PianoHamburgerWhiteKey = styled.path`
  fill: rgb(200, 200, 200);
  transition: fill ease-in 0.5s;
`;

const PianoHamburgerBlackKey = styled.path`
  fill: black;
  opacity: 0;
  transition: opacity linear 0.5s;
  transform: translateX(-3px);
`;

const PianoHamburgerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  transition: background-color linear 1s;

  &:hover {
    cursor: pointer;
    background-color: rgba(150, 150, 150, 0.4);
    transition: background-color linear 1s;
  }

  &:hover ${PianoHamburgerWhiteKey} {
    fill: white;
    transition: fill ease-in 0.3s;
  }

  &:hover ${PianoHamburgerBlackKey} {
    opacity: 1;
    transition: opacity, transform linear 0.5s;
    transform: translateX(0);
  }
`;

const PianoHamburgerSvg = styled.svg`
  height: 30px;
  width: 40px;
`;

export default PianoHamburger;
