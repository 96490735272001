import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Location } from 'assets/Location.svg';
import { ReactComponent as Mail } from 'assets/Mail.svg';
import { ReactComponent as Phone } from 'assets/Phone.svg';

import { Header3 } from 'utils/fontStyles';
import Map from 'components/contact/Map';
import { colors, fontSizes } from 'utils/styleConstants';
import Section from 'components/section';

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8rem 0 1rem 0;
`;

const HeaderContainer = styled.div`
  margin-bottom: 2rem;
`;

const ContactContent = styled.div`
  align-items: center;
  color: ${colors.lightDark};
  display: flex;
  font-size: ${fontSizes.sm};
  line-height: 1.7rem;
`;

const Icon = styled.div`
  height: 2rem;
  width: 2rem;
`;

const Content = styled.div`
  margin: 0.5rem;
`;

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <ContactContainer>
        <div>
          <HeaderContainer>
            <Header3>{t('contact')}</Header3>
          </HeaderContainer>
          <ContactContent>
            <Icon>
              <Phone />
            </Icon>
            <Content>{t('phone')}</Content>
          </ContactContent>
          <ContactContent>
            <Icon>
              <Mail />
            </Icon>
            <Content>{t('email')}</Content>
          </ContactContent>
          <ContactContent>
            <Icon>
              <Location />
            </Icon>
            <Content>{t('location')}</Content>
          </ContactContent>
          <Map />
        </div>
      </ContactContainer>
    </Section>
  );
};

export default Contact;
