import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

export type ButtonSize = 'lg' | 'sm';

export type ButtonType = 'primary' | 'secondary';

interface BaseButtonProps {
  className?: string;
  disabled?: boolean;
  size?: ButtonSize;
  btnType?: ButtonType;
  children: React.ReactNode;
}

export type ButtonProps = BaseButtonProps & ButtonHTMLAttributes<HTMLElement>;

export const Button: React.FC<ButtonProps> = (props) => {
  const { className, btnType, size, children, ...restProps } = props;
  const classes = classNames('btn', className, {
    [`btn-${btnType}`]: btnType,
    [`btn-${size}`]: size,
  });
  return (
    <button className={classes} {...restProps}>
      {children}
    </button>
  );
};

export default Button;
