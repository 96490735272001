import styled from 'styled-components';
import { WaveSingle, WaveMulti } from 'components/Wave';

import { ReactElement } from 'react';

export enum WAVE_TYPE {
  SINGLE = 0,
  MULTI = 1,
}

interface SectionType {
  imageSource?: string;
  isDarkenImage?: boolean;
  children?: ReactElement | ReactElement[];
  waveTopType?: WAVE_TYPE;
  waveBottomType?: WAVE_TYPE;
}

const SectionImage = styled.div<{
  $imageSource?: string;
  $isDarkenImage?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: ${(props) =>
    props.$imageSource
      ? props.$isDarkenImage
        ? `linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.6)), url(${props.$imageSource});`
        : `url(${props.$imageSource})`
      : ''};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
`;

// ----- Section Container -----
const Section = (props: SectionType) => {
  const getWaveTop = () => {
    return props.waveTopType === WAVE_TYPE.SINGLE ? (
      <WaveSingle />
    ) : props.waveTopType === WAVE_TYPE.MULTI ? (
      <WaveMulti />
    ) : (
      ''
    );
  };

  const getWaveBottom = () => {
    return props.waveBottomType === WAVE_TYPE.SINGLE ? (
      <WaveSingle faceup />
    ) : props.waveBottomType === WAVE_TYPE.MULTI ? (
      <WaveMulti faceup />
    ) : (
      ''
    );
  };

  return (
    <SectionImage
      $imageSource={props.imageSource}
      $isDarkenImage={props.isDarkenImage}
    >
      {getWaveTop()}
      {props.children}
      {getWaveBottom()}
    </SectionImage>
  );
};

export default Section;
