import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Logo from 'components/Logo';
import { device, zIndex } from 'utils/styleConstants';
import PianoHamburger from 'components/PianoHamburger';

import Link from 'components/header/Link';
import Language from 'components/header/Language';

const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 5rem 1rem 5rem;
  min-width: 77vw;
  z-index: ${zIndex.navigation};
`;

const NavigationHamburgerMenu = styled.div`
  display: block;
  @media ${device.tablet} {
    display: none;
  }
`;

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <NavigationContainer>
      <Logo />
      <Link t={t} />
      <Language />
      <NavigationHamburgerMenu>
        <PianoHamburger />
      </NavigationHamburgerMenu>
    </NavigationContainer>
  );
};

export default Navigation;
