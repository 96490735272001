import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Section from 'components/section';
import { ContentContainer, Header2, Content } from 'utils/fontStyles';

const Heading = styled(Header2)`
  text-align: center;
`;

const Lessons = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <ContentContainer>
        <div>
          <Heading>{t('OurLessons')}</Heading>
          <Content>{t('lessonsDescription')}</Content>
        </div>
      </ContentContainer>
    </Section>
  );
};

export default Lessons;
