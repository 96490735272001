import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { fontSizes, colors, fontFamily, device } from 'utils/styleConstants';

export const Header1 = styled.h1`
  font-size: ${fontSizes.title_xxxl};
  font-family: ${fontFamily.heading};
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : colors.primary)};
`;

export const Title = styled(Header1)`
  text-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
`;

export const Header2 = styled.h2`
  font-size: ${fontSizes.title_xl};
  font-family: ${fontFamily.heading};
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : colors.primary)};
`;

export const Header3 = styled.div`
  font-size: ${fontSizes.xxl};
  font-family: ${fontFamily.heading};
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : colors.primary)};
`;

export const Paragraph = styled.p`
  font-size: ${fontSizes.md};
  font-family: ${fontFamily.default};
  color: ${(props) => (props.color ? props.color : colors.dark)};
`;

export const Content = styled(Paragraph)`
  line-height: 2.5rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: ${fontFamily.alternate};
  color: ${colors.primaryDark};
  font-weight: 600;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    /* color: inherit; */
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10rem 3rem;

  @media ${device.tablet} {
    margin: 10rem 33%;
  }
`;
