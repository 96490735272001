import DashLine from 'components/footer/DashLine';
import Media from 'components/footer/Media';
import SiteInfo from 'components/footer/SiteInfo';

const Footer = () => {
  return (
    <div>
      <DashLine />
      <Media />
      <SiteInfo />
    </div>
  );
};

export default Footer;
