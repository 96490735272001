import styled from 'styled-components';

import { StyledLink } from 'utils/fontStyles';
import { device, fontSizes } from 'utils/styleConstants';

const NavigationLinks = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const NavigationLink = styled(StyledLink)`
  margin-left: 45px;
  margin-right: 45px;
  font-size: ${fontSizes.sm};
`;

const Link = (props: any) => {
  return (
    <NavigationLinks>
      <NavigationLink to='/'>{props.t('navHomeLink')}</NavigationLink>
      {/* TODO - Create Routing to Career Page */}
      <NavigationLink to='/'>{props.t('navCareerLink')}</NavigationLink>
      <NavigationLink to='/about'>{props.t('navAboutLink')}</NavigationLink>
      {/* TODO - Create Routing to Index Us Page*/}
      <NavigationLink to='/'>{props.t('navContactUsLink')}</NavigationLink>
    </NavigationLinks>
  );
};

export default Link;
