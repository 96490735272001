import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ContentContainer, Header2, Header3, Content } from 'utils/fontStyles';
import Section from 'components/section';
import Button from 'components/button/Button';

import { ReactComponent as IconSingleTailNoteSvg } from 'assets/IconSingleTailNote.svg';

const Heading = styled(Header2)`
  text-align: center;
`;

const OpeningHour = styled(Header3)`
  font-weight: normal;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const About = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <ContentContainer>
        <div>
          <Heading>{t('aboutHeader')}</Heading>
          <OpeningHour>{t('openingHours')}</OpeningHour>
          <OpeningHour>
            {t('weekdayHours')} {t('weekendHours')}
          </OpeningHour>
          <Content>{t('about')}</Content>
          <ButtonContainer>
            <Button>
              {t('buttonEnrolNow')}
              <IconSingleTailNoteSvg className='btn-svg-icon' />
            </Button>
          </ButtonContainer>
        </div>
      </ContentContainer>
    </Section>
  );
};

export default About;
