import { Switch, Route } from 'react-router-dom';

import Footer from 'components/footer';
import Header from 'components/header';

import AboutScreen from 'screens/AboutScreen';
import MainScreen from 'screens/MainPage';

const App = () => {
  return (
    <div>
      <Header />
      <Switch>
        <Route path='/course'>
          <AboutScreen />
        </Route>
        <Route path='/about'>
          <AboutScreen />
        </Route>
        <Route path='/'>
          <MainScreen />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
