import styled from 'styled-components';
import { colors, fontSizes } from 'utils/styleConstants';

const ContentContainer = styled.div`
  color: ${colors.light};
  display: flex;
  font-size: ${fontSizes.xs};
  justify-content: center;
  text-align: center;
  margin: 0 4rem 5rem 4rem;
`;

const SiteInfo = () => {
  return (
    <ContentContainer>
      © Haydn Music Education 2021 | Website Design and Development by
      LittleByte
    </ContentContainer>
  );
};

export default SiteInfo;
